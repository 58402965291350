import React from "react"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import HeroLegals from "@components/Hero/HeroLegals"
import EvaluationContent from "@components/Evaluation/index"
import { graphql } from "gatsby"

function EvaluationTemplate(props) {
  const { internalName, content } = props?.data?.pageData

  return (
    <Layout>
      <Seo
        title="Workgrid Software Evaluation Terms"
        description="Workgrid is a digital workplace platform that simplifies the work day and improves employee experience."
        metaTags="workgrid, digital workplace, enterprise software"
      />
      <HeroLegals title={internalName} />
      <EvaluationContent content={content} />
    </Layout>
  )
}

export default EvaluationTemplate

export const PageQuery = graphql`
  query EvaluationPageQuery {
    pageData: contentfulComponentGeneric(
      internalName: { eq: "Evaluation Agreement" }
    ) {
      id
      internalName
      content: bodyCopy {
        raw
        references {
          id
          link
          label
        }
      }
    }
  }
`
