import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { defaultResolveRichText } from "../../utils/resolve-rich-text/defaultResolveRichText"

const Content = ({ content }) => {
  const contentRef = useRef()

  const scrollHandler = () => {
    if (contentRef?.current) {
      const mainPos =
        window.scrollY + contentRef.current.getBoundingClientRect().top
      const scrollTop = document.querySelector(".topmovebtn")
      if (window.scrollY > mainPos + 100) {
        scrollTop.classList.remove("d-none")
      } else scrollTop.classList.add("d-none")
    }
  }

  const handleTop = () => {
    const mainPos =
      window.scrollY + contentRef.current.getBoundingClientRect().top
    window.scrollTo({
      top: mainPos - 100,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler)
    return () => {
      window.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  return (
    <Container>
      {content && (
        <ContentBlock ref={contentRef}>
          <div className="content-panel">{defaultResolveRichText(content)}</div>
          <span className="topmovebtn d-none" onClick={() => handleTop()}>
            BACK TO TOP
          </span>
        </ContentBlock>
      )}
    </Container>
  )
}

export default Content

const Container = styled.div`
  position: relative;
  min-height: 100%;
  margin: auto;
  max-width: 1170px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`

const ContentBlock = styled.div`
  width: 100%;
  padding: 96px 0;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.16px;
  color: #475467;
  .content-panel {
    max-width: 770px;
    margin: 0 auto;
  }
  @media (max-width: 992px) {
    padding: 64px 0;
  }
  @media (max-width: 768px) {
    padding: 48px 0;
  }
  a {
    color: #002dc2;
  }
  ul,
  ol {
    padding-left: 20px;
    margin: 1rem 0;
  }
  h2 {
    font-weight: 700;
    font-size: 28.83px;
    line-height: 120%;
    letter-spacing: -0.27px;
    color: #101828;
    margin: 3rem 0 1rem 0;
    &:first-child {
      margin-top: 0;
    }
    @media (max-width: 768px) {
      font-size: 25.63px;
      line-height: 120%;
    }
  }
  h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.27px;
    color: #101828;
    margin: 3rem 0 1rem 0;
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    margin: 1rem 0;
  }
  .topmovebtn {
    display: inline-block;
    position: sticky;
    bottom: 1rem;
    left: 100%;
    padding: 14px;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    color: #0022b7;
    border: 1px solid #0022b7;
    border-radius: 40px;
    transition: opacity 0.2s;
    background: #ffffff;
    cursor: pointer;
    opacity: 1;
    &.d-none {
      opacity: 0;
    }
    @media (max-width: 992px) {
      left: 80%;
    }
    @media (max-width: 768px) {
      left: 100%;
    }
  }
`
